<template>
  <b-card>
    <b-card-header>
      Order ID <strong>#{{ this.invoice_number }}</strong>
    </b-card-header>
    <b-card-body>
      <div class="loading" v-if="isLoading == true">
        <div class="sk-three-bounce">
          <div class="sk-child sk-bounce1"></div>
          <div class="sk-child sk-bounce2"></div>
          <div class="sk-child sk-bounce3"></div>
        </div>
      </div>
      <b-row class="mb-4">
        <b-col sm="4">
          <h6>Order Date</h6>
          <div class="mb-3">
            <strong>{{ this.created_at }}</strong>
          </div>
          <h6>Payment Date</h6>
          <div class="mb-3">
            <strong>{{ this.payment_date }}</strong>
          </div>
          <h6>User</h6>
          <div class="mb-3">
            <strong>{{ this.name }} ( {{ this.email }} )</strong>
          </div>
          <h6>Metode Pembayaran</h6>
          <div class="mb-3">
            <strong>{{ this.payment_method }}</strong>
          </div>
          <h6>Status Pembayaran</h6>
          <div class="mb-3">
            <strong>{{ this.payment_status }}</strong>
          </div>
          <h6>Total Pembayaran</h6>
          <div class="mb-3">
            <strong>{{ this.total }}</strong>
          </div>
        </b-col>
        <b-col sm="4">
          <h6>Nama Penerima</h6>
          <div class="mb-3">
            <strong>{{ this.receiver_name }}</strong>
          </div>
          <h6>Nomor Handphone Penerima</h6>
          <div class="mb-3">
            <strong>{{ this.receiver_phone }}</strong>
          </div>
          <h6>Full Address</h6>
          <div class="mb-3">
            <strong>{{ this.receiver_address }}</strong>
          </div>
          <h6>Kecamatan</h6>
          <div class="mb-3">
            <strong>{{ this.district }}</strong>
          </div>
          <h6>Kelurahan</h6>
          <div class="mb-3">
            <strong>{{ this.village }}</strong>
          </div>
          <h6>Kode Pos</h6>
          <div class="mb-3">
            <strong>{{ this.postal_code }}</strong>
          </div>
        </b-col>
        <b-col sm="4">
          <h6>Biaya Pengiriman</h6>
          <div class="mb-3">
            <strong>{{ this.shipping_fee }}</strong>
          </div>
          <h6>Metode Pengiriman</h6>
          <div class="mb-3">
            <strong>{{ this.shipping_method }}</strong>
          </div>
          <h6>Nomor Resi</h6>
          <div class="mb-3">
            <strong>{{ this.tracking_no }}</strong>
          </div>
          <h6>Status Pengiriman</h6>
          <div class="mb-3">
            <strong>{{ this.shipping_status }}</strong>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="items.length > 0">
        <b-col sm>
          <div class="table-responsive-sm mb-5">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="left">Product/Voucher Amount</th>
                  <th>Voucher Price</th>
                  <th class="center">Quantity</th>
                  <th class="right">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td>{{ item.amount }}</td>
                  <td>{{ item.price }}</td>
                  <td>{{ item.qty }}</td>
                  <td>{{ item.total }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
      <!-- <h5 v-if="this.tracking_no === '' || this.tracking_no === '-' && this.payment_status === 'Menunggu Pembayaran'">Reason (Optional untuk membatalkan invoice)</h5>
        <b-row v-if="this.tracking_no === '' || this.tracking_no === '-' && this.payment_status === 'Menunggu Pembayaran'">
          <b-col sm>
            <form v-on:submit.prevent="reject">
                <b-form-group
                  id="reasonInputGroup1"
                  label-for="reason"
                >
                  <b-form-input
                    id="reason"
                    type="text"
                    size="md"
                    v-model="form.reason"
                    aria-describedby="reasonFeedback"
                    placeholder="Alasan jual emas"
                    autocomplete='given-name'
                    autofocus
                  >
                  </b-form-input>
                </b-form-group>
            </form>
          </b-col>
        </b-row> -->
      <b-row>
        <b-col>
          <b-button
            type="button"
            variant="danger"
            class="m-2"
            v-if="
              this.tracking_no === '' ||
              (this.tracking_no === '-' &&
                this.payment_status === 'Menunggu Pembayaran' &&
                $can('cancel_voucher_reseller_transaction'))
            "
            @click="cancelInvoice"
            >Cancel Invoice</b-button
          >
          <!-- <b-button type="button" variant="success" class="m-2" v-if="this.tracking_no === '' || this.tracking_no === '-' && this.payment_status === 'Success' && this.type !== 'digital' && $can('pickup_voucher_reseller_transaction')" @click="requestPickup">Request Pick Up</b-button> -->
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
export default {
  name: "reseller-detail",
  data() {
    return {
      name: "",
      email: "",
      created_at: "",
      payment_date: "",
      payment_method: "",
      payment_status: "",
      total: "",
      invoice_amount: "",
      receiver_name: "",
      receiver_phone: "",
      receiver_address: "",
      district: "",
      village: "",
      postal_code: "",
      shipping_fee: "",
      shippnig_method: "",
      shipping_status: "",
      tracking_no: "",
      items: [],
      type: "",
      form: {
        reason: "",
      },
      isLoading: false,
    };
  },
  created() {
    this.$http
      .get(`reseller/${this.$route.params.id}`)
      .then((response) => {
        let res = response.data.data;

        this.name = res.users.name;
        this.email = res.users.email;
        this.invoice_number = res.invoice_number;
        this.created_at = res.created_at;
        this.payment_date = res.invoices.payment_date;
        this.payment_status = res.invoices.status;
        this.total = res.invoices.amount;
        this.payment_method = res.invoices.payment_method;
        this.receiver_name = res.destination.receiver_name;
        this.receiver_phone = res.destination.receiver_phone;
        this.district = res.destination.district;
        this.village = res.destination.village;
        this.postal_code = res.destination.postal_code;
        this.shipping_status = res.destination.status;
        this.shipping_fee = res.destination.shipping_cost;
        this.receiver_address = res.destination.address;
        this.shipping_method = res.destination.shipping_provider;
        this.tracking_no = res.destination.tracking_no;
        this.items = res.items;
        this.type = res.type;
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.meta.code == 422) {
            this.$toasted.error(
              "Please fill out the form that must be required"
            );
          } else if (error.response.data.meta.code == 400) {
            this.$swal.fire(
              "Failed!",
              error.response.data.meta.message,
              "error"
            );
          }
        }
      });
  },
  methods: {
    cancelInvoice() {
      this.isLoading = true;
      let payload = {
        reason: this.form.reason,
      };
      this.$http
        .post(`cancel-invoice-reseller/${this.$route.params.id}`, payload)
        .then((response) => {
          this.isLoading = false;
          this.$swal
            .fire("Success!", response.data.meta.message, "success")
            .then(() => {
              location.reload();
            });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            if (error.response.data.meta.code == 422) {
              this.$toasted.error(
                "Please fill out the form that must be required"
              );
            } else if (error.response.data.meta.code == 400) {
              this.$swal
                .fire("Failed!", error.response.data.meta.message, "error")
                .then(() => {
                  location.reload();
                });
            }
          }
        });
    },
    requestPickup() {
      this.isLoading = true;

      this.$http
        .post(`request-pickup-reseller/${this.$route.params.id}`)
        .then((response) => {
          this.isLoading = false;
          this.$swal
            .fire("Success!", response.data.meta.message, "success")
            .then(() => {
              location.reload();
            });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            if (error.response.data.meta.code == 422) {
              this.$toasted.error(
                "Please fill out the form that must be required"
              );
            } else if (error.response.data.meta.code == 400) {
              this.$swal
                .fire("Failed!", error.response.data.meta.message, "error")
                .then(() => {
                  location.reload();
                });
            }
          }
        });
    },
  },
};
</script>
